export type QueueCallback<T> = (item: T) => Promise<void>

export class ThrottledQueue<T> {
  queue: T[] = []
  concurrency: number = 2
  running: number = 0
  callback: QueueCallback<T>

  constructor(callback: QueueCallback<T>) {
    this.callback = callback
  } 

  canProcess() {
    return this.running < this.concurrency
  }

  process(item: T) {
    this.running++

    this.callback(item)
      .then(() => {
        this.running--
        this.tryDequeue()
      })
      .catch(() => {
        this.running--
        this.tryDequeue()
      })
  }

  enqueue(item: T) {    
    if (this.canProcess()) {
      this.process(item)
    } else {
      this.queue.push(item)
    }
  }

  tryDequeue() {
    if (!this.canProcess()) {
      return
    }

    const item = this.queue.shift()
    if (item) {
      this.process(item)
    }
  }
}