import { ApiError } from "@/api"

export function isApiError(err: any | null | undefined): ApiError | false {
  if (!err) {
    return false
  }
  return err instanceof ApiError ? err as ApiError : false
}

export function isStatusCode(err: any | null | undefined, statusCode: number): ApiError | false {
  const apiError = isApiError(err)
  return apiError && apiError.status === statusCode ? apiError : false
}