/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MapRequestingProviderOntoRequestingProviderViewModel } from '../models/MapRequestingProviderOntoRequestingProviderViewModel';
import type { NextWorkflowStepViewModel } from '../models/NextWorkflowStepViewModel';
import type { OtterServiceReviewViewModel } from '../models/OtterServiceReviewViewModel';
import type { ProviderSearchRequestViewModel } from '../models/ProviderSearchRequestViewModel';
import type { RemoveServiceReviewListItemViewModel } from '../models/RemoveServiceReviewListItemViewModel';
import type { RenderingProviderMapSearchResultViewModel } from '../models/RenderingProviderMapSearchResultViewModel';
import type { RequestingProviderMapSearchResultViewModel } from '../models/RequestingProviderMapSearchResultViewModel';
import type { ServiceReviewRenderingProviderSearchResultViewModel } from '../models/ServiceReviewRenderingProviderSearchResultViewModel';
import type { ServiceReviewRequestingProviderSearchResultViewModel } from '../models/ServiceReviewRequestingProviderSearchResultViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProvidersService {

    /**
     * @param requestBody 
     * @returns NextWorkflowStepViewModel OK
     * @throws ApiError
     */
    public static removeRequestingProvider(
requestBody: OtterServiceReviewViewModel,
): CancelablePromise<NextWorkflowStepViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Providers/RemoveRequestingProvider',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns NextWorkflowStepViewModel OK
     * @throws ApiError
     */
    public static removeRenderingProvider(
requestBody: RemoveServiceReviewListItemViewModel,
): CancelablePromise<NextWorkflowStepViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Providers/RemoveRenderingProvider',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns NextWorkflowStepViewModel OK
     * @throws ApiError
     */
    public static mapRequestingOntoRenderingProvider(
requestBody: MapRequestingProviderOntoRequestingProviderViewModel,
): CancelablePromise<NextWorkflowStepViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Providers/MapRequestingOntoRenderingProvider',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns ServiceReviewRenderingProviderSearchResultViewModel OK
     * @throws ApiError
     */
    public static searchRenderingProviders(
requestBody: ProviderSearchRequestViewModel,
): CancelablePromise<ServiceReviewRenderingProviderSearchResultViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Providers/SearchRenderingProviders',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns NextWorkflowStepViewModel OK
     * @throws ApiError
     */
    public static mapRenderingProviderSearchResult(
requestBody: RenderingProviderMapSearchResultViewModel,
): CancelablePromise<NextWorkflowStepViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Providers/MapRenderingProviderSearchResult',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns ServiceReviewRequestingProviderSearchResultViewModel OK
     * @throws ApiError
     */
    public static searchRequestingProviders(
requestBody: ProviderSearchRequestViewModel,
): CancelablePromise<ServiceReviewRequestingProviderSearchResultViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Providers/SearchRequestingProviders',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns NextWorkflowStepViewModel OK
     * @throws ApiError
     */
    public static mapRequestingProviderSearchResult(
requestBody: RequestingProviderMapSearchResultViewModel,
): CancelablePromise<NextWorkflowStepViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Providers/MapRequestingProviderSearchResult',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
