import type { App } from 'vue'
import type { RouteLocationNormalized, RouteLocationNormalizedLoaded, Router } from 'vue-router'
import ENV from '@/config/environment'
import { useIdentityStore } from '@/stores/identityStore'
import debugLoggingService from '@/services/debugLoggingService';
import { isApiError, isStatusCode } from '@/shared/apiUtils';

export function useGlobalErrorHandler(app: App<Element>, router: Router) {

  const onError = (error: any) => {
    if (isStatusCode(error, 401)) {
      useIdentityStore().signOut()
    }
  }

  /**
   * Global event handler for all uncaught errors during runtime excluding errors that are
   * thrown during router navigation and navigation guards. Navigation errors are handled
   * by the handler passed to `router.onError`.
   */
  app.config.errorHandler = (err, instance, info) => {
    if (ENV.debugLogging) {
      debugLoggingService.error(`${isApiError(err) ? 'ApiError:' : 'Error:'} ${err}`, info)
      console.groupEnd()
    }
    onError(err)
  }
  
  /**
   * Adds an error handler that is called every time a non caught error happens
   * during navigation. This includes errors thrown synchronously and
   * asynchronously, errors returned or passed to `next` in any navigation
   * guard, and errors occurred when trying to resolve an async component that
   * is required to render a route.
   */
  router.onError((error: any, to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded) => {
    onError(error)
  } )
}