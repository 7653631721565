/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CodeSearchSortBy } from './CodeSearchSortBy';

/**
 * The args possible for a code search
 */
export type SearchCodesArguments = {
    /**
     * Limit
     */
    limit?: number | null;
    /**
     * Offset
     */
    offset?: number;
    /**
     * The code list id
     */
    list?: string | null;
    /**
     * The codes of the items you are looking for
     */
    codes?: Array<string> | null;
    /**
     * A list of search terms to filter by
     */
    'q'?: Array<string> | null;
    /**
     * Return only active codes.  Defaults to true
     */
    activeOnly?: boolean | null;
    /**
     * Collapse NDC codes based on EPA logic
     */
    consolidate?: boolean | null;
    /**
     * The field to sort the results by
     */
    sortBy?: CodeSearchSortBy | null;
    /**
     * Sort direction
     */
    sortDirection?: SearchCodesArguments.sortDirection;
    /**
     * The code only
     */
    codeOnly?: boolean | null;
    /**
     * The first code in a range of codes ( also means you search ProcedureCodesDAO )
     */
    start?: string | null;
    /**
     * The last code in a range of codes ( also means you search ProcedureCodesDAO )
     */
    end?: string | null;
    /**
     * The service date for which to base valid codes
     */
    serviceDate?: string | null;
};

export namespace SearchCodesArguments {

    /**
     * Sort direction
     */
    export enum sortDirection {
        Desc = 'desc',
        Asc = 'asc',
    }


}
