import { defineStore } from "pinia"
import { OverviewService, type LightweightWorkflowViewModel, type RequestTypeEnumStringKeyValuePair, WorkflowService, RequestTypeEnum, type ServiceReview } from "@/api"
import globalFilters from "@/shared/globalFilters"
import type { WorkflowShortcut } from "@/types/workflowShortcut"
import { useProfileStore } from "./profileStore"

export interface Payer {
  payerId: string,
  name: string,
  workflows: LightweightWorkflowViewModel[],
}

export interface State {
  requestTypes: RequestTypeEnumStringKeyValuePair[]
  workflows: LightweightWorkflowViewModel[]
}

export const useOverviewStore = defineStore('overview', {
  state: (): State => ({
    requestTypes: [],
    workflows: [],
  }),
  getters: {
    requestTypeMap(): Map<RequestTypeEnum, RequestTypeEnumStringKeyValuePair> {
      return new Map<RequestTypeEnum, RequestTypeEnumStringKeyValuePair>(this.requestTypes.map(r => [ RequestTypeEnum[r.key ?? ''], r ]))
    },
    payers(): Payer[] {
      return Array.from(this.payerMap.values()).sort((p1, p2) => p1.name.localeCompare(p2.name))
    },
    payerMap(): Map<string, Payer> {
      const map = new Map<string, Payer>()
      for (const workflow of this.workflows) {
        if (!workflow.payerId) {
          continue
        }
        let payer = map.get(workflow.payerId)
        if (!payer) {
          payer = {
            payerId: workflow.payerId,
            name: globalFilters.notSetIfEmpty(workflow.payerName),
            workflows: [],
          }
          map.set(workflow.payerId, payer)
        }
        payer.workflows.push(workflow)
      }

      return map
    },
    workflowShortcuts(): WorkflowShortcut[] {
      /**
       * TODO: this is just mock data, need to get from api
       */
      const profileStore = useProfileStore()
      const shortcuts = [] as WorkflowShortcut[]

      for (const payer of this.payers) {
        if (payer.payerId === 'DefaultPayer') {
          continue
        }
        for (const workflow of payer.workflows) {
          const shortcut = {
            payerId: payer.payerId,
            requestType: RequestTypeEnum[workflow.requestType ?? 'HS'],
            organizationId: profileStore.organizationId,
          } as WorkflowShortcut

          if (shortcut.payerId === "BCBSF") {
            shortcuts.unshift(shortcut)
          } else {
            shortcuts.push(shortcut)
          }
        }
      }
      return shortcuts.length > 5
        ? shortcuts.slice(0, 5)
        : shortcuts
    },
  },
  actions: {
    async initialize(): Promise<void> {
      this.$reset()
      const [overviewResponse, workflowsResponse] = await Promise.all([
        await OverviewService.getOverview(),
        await WorkflowService.getWorkflows(),
      ])
      this.requestTypes = overviewResponse?.requestTypes ?? []
      this.workflows = workflowsResponse
    },
  },
})