/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateFromExternalResultViewModel } from '../models/CreateFromExternalResultViewModel';
import type { LightweightWorkflowViewModel } from '../models/LightweightWorkflowViewModel';
import type { NextWorkflowStepViewModel } from '../models/NextWorkflowStepViewModel';
import type { OtterServiceReviewViewModel } from '../models/OtterServiceReviewViewModel';
import type { RequestTypeEnum } from '../models/RequestTypeEnum';
import type { ServiceReview } from '../models/ServiceReview';
import type { ValidateServiceReviewRequestViewModel } from '../models/ValidateServiceReviewRequestViewModel';
import type { WorkflowOverviewViewModel } from '../models/WorkflowOverviewViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorkflowService {

    /**
     * @returns LightweightWorkflowViewModel OK
     * @throws ApiError
     */
    public static getWorkflows(): CancelablePromise<Array<LightweightWorkflowViewModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Workflow/GetWorkflows',
        });
    }

    /**
     * Get the workflow overview
     * @param payerId PayerId
     * @param requestType RequestType
     * @param organizationId 
     * @param isOriginalEssentialsWorkflow 
     * @returns WorkflowOverviewViewModel OK
     * @throws ApiError
     */
    public static getWorkflowOverview(
payerId: string,
requestType: RequestTypeEnum,
organizationId?: string,
isOriginalEssentialsWorkflow?: boolean,
): CancelablePromise<WorkflowOverviewViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Workflow/GetWorkflowOverview',
            query: {
                'payerId': payerId,
                'requestType': requestType,
                'organizationId': organizationId,
                'isOriginalEssentialsWorkflow': isOriginalEssentialsWorkflow,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns NextWorkflowStepViewModel OK
     * @throws ApiError
     */
    public static resetServiceReview(
requestBody: OtterServiceReviewViewModel,
): CancelablePromise<NextWorkflowStepViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Workflow/ResetServiceReview',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @param skipValidation 
     * @param alwaysMoveOn 
     * @returns NextWorkflowStepViewModel OK
     * @throws ApiError
     */
    public static completeStep(
requestBody: OtterServiceReviewViewModel,
skipValidation?: boolean,
alwaysMoveOn?: boolean,
): CancelablePromise<NextWorkflowStepViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Workflow/CompleteStep',
            headers: {
                'skipValidation': skipValidation,
                'alwaysMoveOn': alwaysMoveOn,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param dashboardId 
     * @param workflowStepId 
     * @returns NextWorkflowStepViewModel OK
     * @throws ApiError
     */
    public static getServiceReviewAtStep(
dashboardId: string,
workflowStepId: number,
): CancelablePromise<NextWorkflowStepViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Workflow/GetServiceReviewAtStep',
            query: {
                'dashboardId': dashboardId,
                'workflowStepId': workflowStepId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns NextWorkflowStepViewModel OK
     * @throws ApiError
     */
    public static validateServiceReview(
requestBody: ValidateServiceReviewRequestViewModel,
): CancelablePromise<NextWorkflowStepViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Workflow/ValidateServiceReview',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param dashboardId 
     * @returns WorkflowOverviewViewModel OK
     * @throws ApiError
     */
    public static loadServiceReview(
dashboardId: string,
): CancelablePromise<WorkflowOverviewViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Workflow/LoadServiceReview/{dashboardId}',
            path: {
                'dashboardId': dashboardId,
            },
        });
    }

    /**
     * @param dashboardId 
     * @returns WorkflowOverviewViewModel OK
     * @throws ApiError
     */
    public static copyServiceReview(
dashboardId: string,
): CancelablePromise<WorkflowOverviewViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Workflow/CopyServiceReview/{dashboardId}',
            path: {
                'dashboardId': dashboardId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns CreateFromExternalResultViewModel OK
     * @throws ApiError
     */
    public static createFromExternal(
requestBody: ServiceReview,
): CancelablePromise<CreateFromExternalResultViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Workflow/CreateFromExternal',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
