/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NextWorkflowStepViewModel } from '../models/NextWorkflowStepViewModel';
import type { ProcedureMapSearchResultViewModel } from '../models/ProcedureMapSearchResultViewModel';
import type { RemoveServiceReviewListItemViewModel } from '../models/RemoveServiceReviewListItemViewModel';
import type { SearchResultMapResponseViewModel } from '../models/SearchResultMapResponseViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProceduresService {

    /**
     * @param requestBody 
     * @returns NextWorkflowStepViewModel OK
     * @throws ApiError
     */
    public static removeProcedure(
requestBody?: RemoveServiceReviewListItemViewModel,
): CancelablePromise<NextWorkflowStepViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Procedures/RemoveProcedure',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns SearchResultMapResponseViewModel OK
     * @throws ApiError
     */
    public static mapProcedureSearchResult(
requestBody?: ProcedureMapSearchResultViewModel,
): CancelablePromise<SearchResultMapResponseViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Procedures/MapProcedureSearchResult',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
