import ENV from '@/config/environment'
import { useIdentityStore } from '@/stores/identityStore'
import { createRouter, createWebHistory, type NavigationGuardNext, type RouteLocationNormalized } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'workflowSelection',
      component: () => import('../views/WorkflowSelectionView.vue')
    },
    {
      path: '/authorization/:dashboardId?',
      name: 'authorization',
      component: () => import('../views/AuthorizationRequestView.vue'),
      props:  ({ params }) => ({
        dashboardId: params.dashboardId,
      }),
    },
    {
      path: '/epaResponse',
      name: 'epaResponse',
      component: () => import('../views/EpaResponseView.vue'),
      props:  ({ params }) => ({
        epaResponse: params.dashboardId,
      }),
    },
    {
      path: '/blocklist',
      name: 'blockList',
      component: () => import('../views/BlockList.vue')
    },
  ]
})

const ifAuthenticated = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const identityStore = useIdentityStore()
  if (identityStore.isAuthenticated) {
    return next()
  }
  if (await identityStore.initialize()) {
    return next()
  }
  identityStore.signOut()
  return next() // still necessary for Vue to think this guard is valid
}

const ifNotAuthenticated = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const identityStore = useIdentityStore()
  if (identityStore.isAuthenticated) {
    return next(ENV.defaultRoute)
  }

  return next()
}

export function initializeRouter() {
  for (const route of router.getRoutes()) {
    if (route.beforeEnter) {
      continue
    }
    if (route.meta.requireAnonymous) {
      route.beforeEnter = ifNotAuthenticated
    } else if (!route.meta.allowAnonymous) {
      route.beforeEnter = ifAuthenticated
    }
  }
}

export default router
