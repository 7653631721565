import type { BlockItemConfig } from "@/components/blockConfigs/_Types";

export default [
  {
    type: 'InputText',
    label: 'Address Line 1',
    prop: 'addressLine1'
  },
  {
    type: 'InputText',
    label: 'Address Line 2',
    prop: 'addressLine2'
  },
  {
    type: 'InputText',
    label: 'City',
    prop: 'city'
  },
  {
    type: 'InputSearch',
    label: 'State',
    prop: 'stateCode'
  },
  {
    type: 'InputText',
    label: 'Zip Code',
    prop: 'zipCode',
    mask: '#####-####'
  },
]  as BlockItemConfig[]

export interface Address {
  addressLine1?: string | null
  addressLine2?: string | null
  city?: string | null
  state?: string | null
  stateCode?: string | null
  zipCode?: string | null
}