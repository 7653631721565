import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { generateW3CId } from '@microsoft/applicationinsights-core-js'

const defaultOptions = {
  config: {},
  applicationName: 'Vue App',
  environmentName: 'Unknown',
}

export default {
  install(app, ...options) {
    try {
      const option = options?.length
        ? options[0]
        : defaultOptions

      const appInsights = new ApplicationInsights({
        config: option.config ?? {},
      })
      appInsights.loadAppInsights()

      const baseName = `${option.applicationName} / ${option.environmentName}`

      if (option.router) {
        option.router.beforeEach((route, from, next) => {
          const routeName = route.name
          const name = `${baseName} / ${routeName}`
          appInsights.context.telemetryTrace.traceID = generateW3CId()
          appInsights.context.telemetryTrace.name = routeName
          appInsights.startTrackPage(name)
          next()
        })

        option.router.afterEach(route => {
          const routeName = route.name
          const name = `${baseName} / ${routeName}`
          const url = location.protocol + '//' + location.host + route.fullPath
          appInsights.stopTrackPage(name, url)
          appInsights.flush()
        })
      }

      if (option.customLogger) {
        option.customLogger.appInsights = appInsights
        if (option.customLogger.telemetryInitializer) {
          appInsights.addTelemetryInitializer(option.customLogger.telemetryInitializer)
        }
      }

    } catch (err) {
      console.warn(err)
    }
  }
}