import addressConfig from '@/components/blockConfigs/Address'
import type { BlockItemConfig } from "./_Types";

export default [
  {
    type: 'InputText',
    label: 'Subscriber Member ID',
    prop: 'memberId'
  },
  {
    type: 'InputText',
    label: 'Subscriber Group Number',
    prop: 'groupNumber'
  },
  {
    type: 'InputText',
    label: 'Subscriber Group Name',
    prop: 'groupName'
  },
  {
    type: 'InputText',
    label: 'Subscriber First Name',
    prop: 'firstName'
  },
  {
    type: 'InputText',
    label: 'Subscriber Middle Name',
    prop: 'middleName'
  },
  {
    type: 'InputText',
    label: 'Subscriber Last Name',
    prop: 'lastName'
  },
  {
    type: 'InputText',
    label: 'Subscriber Suffix',
    prop: 'suffix'
  },
  ...structuredClone(addressConfig),
] as BlockItemConfig[]