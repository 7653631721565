<template>
  <nav class="modern-theme">
    <RouterLink to="/signOut">Sign Out</RouterLink>
    <RouterLink to="/blocklist">Blocks</RouterLink>
    <RouterLink to="/">Authorization</RouterLink>
    <TestHandlerInput v-if="isAuthRoute" />
    <span>Build: <strong>{{ buildNum }}</strong></span>
    <span v-if="dashboardId">Dashboard ID: <strong>{{ dashboardId }}</strong></span>
    <span v-if="paRequestId">PA Request ID: <strong>{{ paRequestId }}</strong></span>
    <span class="nav-right">
      <CompleteStepOverrides />
    </span>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CompleteStepOverrides from './authorization/CompleteStepOverrides.vue'
import TestHandlerInput from './authorization/TestHandlerInput.vue'
import { mapStores } from 'pinia'
import { useAuthorizationStore } from '@/stores/authorizationStore'

export default defineComponent({
  name: 'Navigation',
  components: {
    CompleteStepOverrides,
    TestHandlerInput,
  },
  computed: {
    buildNum(): string {
      return BUILDNUM
    },
    ...mapStores(useAuthorizationStore),
    dashboardId() {
      return this.authorizationStore.serviceReview?.dashboardId
    },
    paRequestId() {
      return this.authorizationStore.otterServiceReview?.paRequestId
    },
    isAuthRoute() {
      return this.$route.name === 'authorization'
    },
  },
})
</script>

<style scoped>
nav {
  position: fixed;
  top:0; left:0; right:0;
  display: flex;
  gap: 1rem;
  padding: 0 1rem;
  background-color: var(--authai-base-color-100);
  align-items: center;
  min-height: 40px;
  z-index: 500;
}

.nav-right {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>