import patientConfig from '@/components/blockConfigs/Patient'
import subscriberConfig from '@/components/blockConfigs/Subscriber'

let p = patientConfig.map(p => {
  p.prop = `patient.${p.prop}`
  return p
})
let s = subscriberConfig.map(s => {
  s.prop = `subscriber.${s.prop}`
  return s
})

export default [...s, ...p]