import { deepClone } from '@/shared/utils'

export default {
  getPropertyValue(obj: object, propPath: string): any {
    if (!obj) {
      return obj
    }

    const firstPropResult = this.splitFirstProp(propPath)
    if (firstPropResult) {
      this.ensureNestedObjExists(obj, firstPropResult.firstProp)
      return this.getPropertyValue(obj[firstPropResult.firstProp], firstPropResult.newPropPath)
    }

    const arrayResult = this.splitArrayIndex(propPath)
    if (arrayResult) {
      this.ensureNestedArrayExists(obj, arrayResult.prop)
      const arr = arrayResult.prop ? obj[arrayResult.prop] : obj
      if (arrayResult.propPath) {
        return this.getPropertyValue(arr[arrayResult.index], arrayResult.propPath)
      } else {
        return arr[arrayResult.index]
      }
    }

    return obj[propPath]
  },
  setPropertyValue(obj: object, propPath: string, value: any): void {
    const firstPropResult = this.splitFirstProp(propPath)
    if (firstPropResult) {
      this.ensureNestedObjExists(obj, firstPropResult.firstProp)
      return this.setPropertyValue(obj[firstPropResult.firstProp], firstPropResult.newPropPath, value)
    }

    const arrayResult = this.splitArrayIndex(propPath)
    if (arrayResult) {
      this.ensureNestedArrayExists(obj, arrayResult.prop)
      const arr = arrayResult.prop ? obj[arrayResult.prop] : obj
      this.ensureArrayIndexExists(arr, arrayResult.index)
      if (arrayResult.propPath) {        
        this.setPropertyValue(arr[arrayResult.index], arrayResult.propPath, value)
      } else {
        arr[arrayResult.index] = value
      }
      return
    }

    if (!obj) {
      obj = {}
    }
    obj[propPath] = value
  },
  splitFirstProp(propPath: string): { firstProp: string, newPropPath: string } | null {
    const match = /^(?<firstProp>\w+)\.(?<newPropPath>.+)$/.exec(propPath)
    if (match?.groups) {
      return { firstProp: match.groups.firstProp, newPropPath: match.groups.newPropPath }
    }
    return null
  },
  splitArrayIndex(propPath: string): { prop: string | undefined, index: number, propPath: string } | null {
    const match = /^(?<prop>\w+)?\[(?<index>\d+)\](\.?(?<propPath>[\w\.\[\]]+))?$/.exec(propPath)
    if (match?.groups) {
      return { prop: match.groups.prop, index: parseInt(match.groups.index), propPath: match.groups.propPath }
    }
    return null
  },
  ensureNestedObjExists(obj: object, prop: string) {
    const current = obj[prop]
    if (!current || !(current instanceof Object)) {
      obj[prop] = {}
    }
  },
  ensureNestedArrayExists(obj: object, prop: string | undefined) {
    if (prop) {
      const current = obj[prop]
      if (!current || !(current instanceof Array)) {
        obj[prop] = []
      }
    } else {
      obj = []
    }
  },
  ensureArrayIndexExists(arr: Array<any>, index: number, defaultItem: any = {}) {
    if (arr.length <= index) {
      const numToAdd = index + 1 - arr.length
      arr.splice(arr.length, 0, ...Array.from({ length: numToAdd }, () => deepClone(defaultItem)))
    }
  },
}