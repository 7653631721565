// import ENV from '@/config/environment'
import { useAuthorizationStore } from '@/stores/authorizationStore'
import type { ApplicationInsights } from "@microsoft/applicationinsights-analytics-js"
import type { ITelemetryItem } from "@microsoft/applicationinsights-web"
import type {
  ILogEventData,
  LogEventData,
} from "@/types/Logger"



export class Logger {
  appInsights: ApplicationInsights |  undefined
  telemetryInitializer(item: ITelemetryItem) {
    if (!item.data) {
      item.data = {}
    }

    const event = { 
      application: 'Otter',
      // environment:  'Otter' + ENV.name,
      build: BUILDNUM || 'Local',
      windowHeight: window?.innerHeight || 'unknown',
      windowWidth: window?.innerWidth || 'unknown',
      screenHeight: window?.screen?.availHeight || 'unknown',
      screenWidth: window?.screen?.availWidth || 'unknown',
      userGuid: useAuthorizationStore().serviceReview?.userId || 'unknown',
      otterDraftDashboardId: useAuthorizationStore().serviceReview?.dashboardId || 'unknown',
    } as LogEventData

    Object.assign(item.data, event)
  }
  
  logOtterEvent(eventName: string, data?: ILogEventData) {
    if (!this.appInsights) {
      return;
    }

    try {
      this.appInsights.trackEvent({ name: eventName }, { ...data })
    } catch (err) {
      console.warn(err)
    }
  }
  logTestEvent() {
    try {
      this.logOtterEvent('test-event', {})
    } catch (err) {
      console.warn(err)
    }
  }
  logLoadWorkflow(source: string) {
    try {
      this.logOtterEvent('load-workflow', { source: source })
    } catch (err) {
      console.warn(err)
    }
  }
  logSkipRevalidateProps(serviceReviewProperty: string) {
    try {
      this.logOtterEvent('draft-updated-skipped-revalidation', { serviceReviewProperty: serviceReviewProperty })
    } catch (err) {
      console.warn(err)
    }
  }
  logMFEFailedToLoad(host: string) {
    try {
      this.logOtterEvent('MFE-failed-to-load', { host: host })
    } catch (err) {
      console.warn(err)
    }
  }
  // logSocketConnectRetry(delayMs) {
  //   try {
  //     this.logOtterEvent('socket-connection-failed-retrying', { delayMs })
  //   } catch(err) {
  //     console.warn(err)
  //   }
  // },
  // logSocketConnectRetyExceeded() {
  //   try {
  //     this.logOtterEvent('socket-connection-failed-retry-exceeeded', {})
  //   } catch(err) {
  //     console.warn(err)
  //   }
  // },
  // logUploadEvent(eventName, files) {
  //   try {
  //     // files may be an array or FileList so we must use an explicit for loop
  //     const fileSummaries = []
  //     for (var i = 0; i < files.length; i++) {
  //       var file = files[i]
  //       fileSummaries.push({
  //         size: file.size,
  //         type: file.type,
  //       })
  //     }

  //     this.logOtterEvent(eventName, {
  //       files: fileSummaries,
  //       numberFiles: fileSummaries.length,
  //     })
  //   } catch(err) {
  //     console.warn(err)
  //   } 
  // },
  // logUploadError(uploadQueueEntry) {
  //   try {
  //     this.logOtterEvent('upload-error', {
  //       error: uploadQueueEntry.error,
  //       size: uploadQueueEntry.totalBytes,
  //       type: uploadQueueEntry.fileType,
  //     })
  //   } catch(err) {
  //     console.warn(err)
  //   }
  // },  
  // // logMissingSupportedProcedureWarning() {
  // //   const store = useAuthorizationStore()
  // //   try {
  // //     const stemps = store.state.createPa.draftPaState?.requestedStemp?.map(
  // //       (r) => r.stempCode.code
  // //     )
  // //     this.logOtterEvent('missing-supported-procedure', { stemps })
  // //   } catch(err) {
  // //     console.warn(err)
  // //   }
  // // },
  // // logMissingRequirementsWarning() {
  // //   const store = useAuthorizationStore()
  // //   try {
  // //     this.logOtterEvent('missing-required-props', {
  // //       missingRequiredProps: store.getters['createPa/missingRequiredProps'],
  // //       missingProcedureProps: store.getters['createPa/missingProcedureProps'],
  // //     })
  // //   } catch(err) {
  // //     console.warn(err)
  // //   }
  // // },
  // logAttestationSkipped(stemp) {
  //   try {
  //     this.logOtterEvent('attestation-skipped', { stemp })
  //   } catch(err) {
  //     console.warn(err)
  //   }
  // },
  // logMFEFailedToLoad(component, host) {
  //   try {
  //     this.logOtterEvent('MFE-failed-to-load', { component: component, host: host })
  //   } catch (err) {
  //     console.warn(err)
  //   }
  // },
  // logMFEFailedToAttach(component) {
  //   try {
  //     this.logOtterEvent('MFE-failed-to-attach', { component: component })
  //   } catch (err) {
  //     console.warn(err)
  //   }
  // },
  // logMemberLookup(statusCode, responseMessage, params) {
  //   try {
  //     this.logOtterEvent('member-lookup', {
  //       statusCode,
  //       responseMessage,
  //       includesMemberId: !!params.memberId,
  //       includesDateOfBirth: !!params.dateOfBirth,
  //       includesLastName: !!params.lastName,
  //       includesFirstName: !!params.firstName,
  //     })
  //   } catch(err) {
  //     console.warn(err)
  //   }
  // },
  // logMySubmissionsTabChanged(tabName) {
  //   try {
  //     this.logOtterEvent('my-submissions-tab-changed', { tabName })
  //   } catch(err) {
  //     console.warn(err)
  //   }
  // },
  // logCoverageDetailsExpanded() {
  //   try {
  //     this.logOtterEvent('coverage-details-expanded', {})
  //   } catch(err) {
  //     console.warn(err)
  //   }
  // },
  // logContinueDraft(from, oliveTrackingId) {
  //   try {
  //     this.logOtterEvent('draft-continued', { from }, oliveTrackingId)
  //   } catch(err) {
  //     console.warn(err)
  //   }
  // },
  // logAbandonedSupportForm(summary, oliveTrackingId) {
  //   try {
  //     this.logOtterEvent(
  //       'support-form-abandoned',
  //       { summary },
  //       oliveTrackingId)
  //   } catch(err) {
  //     console.warn(err)
  //   }
  // },
  // logAiAnswerChanged(questionId, stempCode) {
  //   try {
  //     this.logOtterEvent(
  //       'ai-attestation-answer-changed', { 
  //         stemp: stempCode,
  //         questionId,
  //       })
  //   } catch(err) {
  //     console.warn(err)
  //   }
  // },
  // logTimeWaitingForDocumentsAnalyzed(timespanMilliseconds) {
  //   try {
  //     this.logOtterEvent('time-waiting-for-documents-analyzed', { timespanMilliseconds })
  //   } catch(err) {
  //     console.warn(err)
  //   }
  // },
  // logShowNewVersionMessage() {
  //   try {
  //     this.logOtterEvent('new-version-message-displayed', {})
  //   } catch (err) {
  //     console.warn(err)
  //   }
  // },
  // logDateEntered(field, onPaste, isValid) {
  //   try {
  //     this.logOtterEvent('date-entered', {
  //       field: field || 'unknown',
  //       onPaste,
  //       onBlur: !onPaste,
  //       isValid,
  //     })
  //   } catch(err) {
  //     console.warn(err)
  //   }
  // },
  // logCreationStepUpdated(creationStep) {
  //   try {
  //     this.logOtterEvent('creation-step-updated', { creationStep })
  //   } catch(err) {
  //     console.warn(err)
  //   }
  // },
  // logDraftUpdated(action, params) {
  //   try {
  //     this.logOtterEvent('draft-updated', { action, ...params })
  //   } catch(err) {
  //     console.warn(err)
  //   }
  // },
  // logValuePasted(action, params) {
  //   try {
  //     this.logOtterEvent('value-pasted', { action, ...params })
  //   } catch(err) {
  //     console.warn(err)
  //   }
  // },
  // logDetailTabChanged(tabName) {
  //   try {
  //     this.logOtterEvent('detail-tab-changed', { tabName })
  //   } catch(err) {
  //     console.warn(err)
  //   }
  // },
  // logWaitForPayerResponseClose(event) {
  //   try {
  //     this.logOtterEvent('detail-wait-for-payer-close', { type: event })
  //   } catch (err) {
  //     console.warn(err)
  //   }
  // },
  // logShowAllResults(field) {
  //   try {
  //     this.logOtterEvent('show-all-results-clicked', { field })
  //   } catch(err) {
  //     console.warn(err)
  //   }
  // },
  // logShowSessionExpiringModal() {
  //   try {
  //     this.logOtterEvent('session-expiring-modal-displayed', {})
  //   } catch (err) {
  //     console.warn(err)
  //   }
  // },
  // logSignOut(userTriggered) {
  //   try {
  //     this.logOtterEvent('sign-out', { userTriggered: !!userTriggered })
  //   } catch (err) {
  //     console.warn(err)
  //   }
  // },
  // logContinueSession() {
  //   try {
  //     this.logOtterEvent('continue-session-from-modal', {})
  //   } catch (err) {
  //     console.warn(err)
  //   }
  // },
  // logNavigation(from, to, timeOnPreviousView) {
  //   try {
  //     this.logOtterEvent('navigation-occurred', { from, to, timeOnPreviousView })
  //   } catch (err) {
  //     console.warn(err)
  //   }
  // },
  // logRequestingProviderSelected() {
  //   try {
  //     this.logOtterEvent('requesting-provider-selected', {})
  //   } catch (err) {
  //     console.warn(err)
  //   }
  // },
  // logRequestingProviderRemoved() {
  //   try {
  //     this.logOtterEvent('requesting-provider-removed', {})
  //   } catch (err) {
  //     console.warn(err)
  //   }
  // },
}

const logger = new Logger()

/**
 * @ignore
 */
export default logger
