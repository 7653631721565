import type { BlockItemConfig } from "@/components/blockConfigs/_Types";

export default [
  {
    type: 'InputDate',
    label: 'Start Date',
    prop: 'homeHealthStartDate'
  },
  {
    type: 'InputDate',
    label: 'Certification Period Start Date',
    prop: 'homeHealthCertificationPeriodStartDate'
  },
  {
    type: 'InputDate',
    label: 'Certification Period End Date',
    prop: 'homeHealthCertificationPeriodEndDate'
  },
] as BlockItemConfig[]