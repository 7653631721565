import type { RouteLocationRaw } from 'vue-router'
import { configureApi } from './api'
import debugLoggingService from '@/services/debugLoggingService'

interface EnvironmentProps {
  name: string
  api: string
  host: string
  debugLogging?: boolean,
  showConsole?: boolean,
  hasSignIn?: boolean
  signInUrlOrRoute: RouteLocationRaw,
  defaultRoute: RouteLocationRaw,
  APPINSIGHTS: string,
}

export class Environment {
  name: string
  api: string
  host: string
  debugLogging: boolean
  showConsole: boolean

  /** True if this app has a local sign-in view enabled, false otherwise. */
  hasSignIn: boolean

  /** The Vue router route of the sign-in view if enabled or absolute url of external sign-in view. (string) */
  signInUrlOrRoute: RouteLocationRaw

  /** The Vue router route to default the user to (e.g. where the user is redirected after sign-in). */
  defaultRoute: RouteLocationRaw
  APPINSIGHTS: string

  constructor(props: EnvironmentProps) {
    this.name = props.name
    this.api = props.api
    this.host = props.host
    this.debugLogging = props.debugLogging ?? false
    this.showConsole = props.showConsole ?? false
    this.hasSignIn = props.hasSignIn ?? false
    this.signInUrlOrRoute = props.signInUrlOrRoute
    this.defaultRoute = props.defaultRoute
    this.APPINSIGHTS = props.APPINSIGHTS
  }

  get isLocal() {
    return this.name === 'Local'
  }
}

const ENV: Environment = (() => {
  /*
   * TODO: Would it be better for essentials to be able to pass an environment identifier to us
   * when loading the iframe? It should also give us the sign-in view url for us to be able to
   * redirect to when unauthorized.
   */
  const host = window.location.hostname
  const devAPPINSIGHTS = APPINSIGHTKEY

  let api = 'https://dev-api.otter.azn.availity.com' //'https://localhost:7259'
  let defaultRoute = { path: '/' }
  let env = new Environment({
    name: 'Local',
    api,
    host,
    debugLogging: false,
    showConsole: true,
    hasSignIn: true,
    signInUrlOrRoute: { name: 'signIn' },
    defaultRoute,
    APPINSIGHTS: devAPPINSIGHTS,
  })

  switch (host) {
    case 'test-ui.otter.azn.availity.com':
      api = 'https://test-api.otter.azn.availity.com'
      env = new Environment({
        name: 'Test',
        host,
        api,
        showConsole: true,
        debugLogging: true,
        hasSignIn: true,
        signInUrlOrRoute: { name: 'signIn' }, // 'https://qa-apps.availity.com/web/onboarding/availity-fr-ui/?p:lm=1698148523#/login',
        defaultRoute,
        APPINSIGHTS: devAPPINSIGHTS,
      })
      break
    
    case 'ui-authai.qa-apps.availity.com':
      api = 'https://api-authai.qa-apps.availity.com'
      env = new Environment({
        name: 'Test',
        host,
        api,
        showConsole: true,
        debugLogging: true,
        hasSignIn: true,
        signInUrlOrRoute: { name: 'signIn' },
        defaultRoute,
        APPINSIGHTS: devAPPINSIGHTS,
      })
      break

    case 'ui-authai.test-apps.availity.com':
      api = 'https://api-authai.test-apps.availity.com'
      env = new Environment({
        name: 'Test',
        host,
        api,
        showConsole: true,
        debugLogging: true,
        hasSignIn: true,
        signInUrlOrRoute: { name: 'signIn' },
        defaultRoute,
        APPINSIGHTS: devAPPINSIGHTS,
      })
      break
    
    case 'stage-ui.otter.azs.availity.com':
      api = 'https://stage-api.otter.azs.availity.com'
      env = new Environment({
        name: 'Staging',
        host,
        api,
        showConsole: true,
        debugLogging: true,
        hasSignIn: true,
        signInUrlOrRoute: { name: 'signIn' },
        defaultRoute,
        APPINSIGHTS: devAPPINSIGHTS,
      })
      break
    
    case 'ui-authai.apps.availity.com':
      api = 'https://api-authai.apps.availity.com'
      env = new Environment({
        name: 'Production',
        host,
        api,
        showConsole: true,
        hasSignIn: true,
        signInUrlOrRoute: { name: 'signIn' },
        defaultRoute,
        APPINSIGHTS: devAPPINSIGHTS,
      })
      break
      
    default:
      api = 'https://dev-api.otter.azn.availity.com'
      env = new Environment({
        name: 'Development',
        host,
        api,
        showConsole: true,
        debugLogging: true,
        hasSignIn: true,
        signInUrlOrRoute: { name: 'signIn' }, // 'https://qa-apps.availity.com/web/onboarding/availity-fr-ui/?p:lm=1698148523#/login',
        defaultRoute,
        APPINSIGHTS: devAPPINSIGHTS,
      })
      break
  }

  configureApi(api)
  debugLoggingService.debugEnabled = env.debugLogging
  return env
})();

export default ENV