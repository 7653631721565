/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OtterServiceReviewViewModel } from './OtterServiceReviewViewModel';

export type ProviderSearchRequestViewModel = {
    workflowStepId?: number | null;
    query?: string | null;
    otterServiceReview: OtterServiceReviewViewModel;
    providerType: ProviderSearchRequestViewModel.providerType;
    providerSearchMethod: number;
    /**
     * How many results do you want returned
     */
    take: number;
};

export namespace ProviderSearchRequestViewModel {

    export enum providerType {
        Practitioner = 'P',
        Facility = 'F',
        Group = 'G',
    }


}
