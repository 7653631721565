import type { BlockItemConfig } from "@/components/blockConfigs/_Types";

export default [
  {
    type: 'InputDate',
    label: 'From Date',
    prop: 'fromDate'
  },
  {
    type: 'InputDate',
    label: 'To Date',
    prop: 'toDate'
  },
  {
    type: 'InputSelect',
    label: 'Review Type',
    prop: 'supplementalInformation.reviewType',
  },
] as BlockItemConfig[]