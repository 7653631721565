interface Map<K, V> {
  filter: (predicate: (value: V) => unknown) => V[];
}

Map.prototype.filter = function (predicate) {
  const result = [] as any[]
  for (const val of this.values()) {
    if (predicate(val)) {
      result.push(val)
    }
  }
  return result
}