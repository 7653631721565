import type { BlockItemConfig } from '@/components/blockConfigs/_Types'

export default [
  {
    type: 'InputText',
    label: 'Contact Name',
    prop: 'contactName'
  },
  {
    type: 'InputText',
    label: 'Contact First Name',
    prop: 'contactFirstName'
  },
  {
    type: 'InputText',
    label: 'Contact Last Name',
    prop: 'contactLastName'
  },
  {
    type: 'InputText',
    inputType: 'tel',
    label: 'Contact Phone',
    prop: 'contactPhone'
  },
  {
    type: 'InputText',
    label: 'Extension',
    prop: 'contactPhoneExtension'
  },
  {
    type: 'InputText',
    inputType: 'tel',
    label: 'Contact Fax',
    prop: 'contactFax'
  },
  {
    type: 'InputText',
    inputType: 'email',
    label: 'Email',
    prop: 'email'
  },
  {
    type: 'InputText',
    inputType: 'email',
    label: 'Email',
    prop: 'emailAddress'
  },
] as BlockItemConfig[]