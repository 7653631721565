import { isApiError } from '@/shared/apiUtils'

// https://developer.chrome.com/docs/devtools/console/format-style
const logStyle = `
  color: #00AAAA;
`
const errorLogStyle = `
  color: #AA0000;
  font-style: bold;
`
const warningLogStyle = `
  color: #d2c057;
`

enum LogLevel {
  Info,
  Warning,
  Error,
}

class DebugLoggingService {
  debugEnabled: boolean = false

  log(message: string, obj: any | undefined = undefined, level?: LogLevel) {
    const isError = level === LogLevel.Error
    const isWarning = level === LogLevel.Warning

    if (!this.debugEnabled && !isError) {
      return
    }
    
    if (isError) {
      console.groupCollapsed('%cERROR | AUTH AI DEBUG LOG | %s', errorLogStyle, message)
    } else if (isWarning) {
      console.groupCollapsed('%cWARN | AUTH AI DEBUG LOG | %s', warningLogStyle, message)
    } else {
      console.groupCollapsed(`AUTH AI DEBUG LOG | ${message}`)
    }
    
    const formatted = JSON.stringify(obj ?? {}, undefined, 2).replace(/\"password\":.*$/igm, '')
    
    if (isError) {
      console.error(`%s`, formatted)
    } else if (level === LogLevel.Warning) {
      console.warn(`%s`, formatted)
    } else {
      console.log(`%c%s`, logStyle, formatted)
    }
    
    console.groupEnd()
  }

  warning(message: string, obj: any | undefined) {
    this.log(message, obj, LogLevel.Warning)
  }

  error(message: string, obj: any | undefined = undefined) {
    if (isApiError(obj)) {
      return this.log(`${obj.status} ${obj.statusText} | ${message}`, obj, LogLevel.Error)
    }
    this.log(message, obj, LogLevel.Error)
  }
}

const instance = new DebugLoggingService()
export default instance