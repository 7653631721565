<template>
  <div class="autocomplete-input">
    <div class="input-wrap">
      <input type="text" v-model="testHandlerId" placeholder="Test handler suite id"/>
      <span class="icon clear" @click="clearTestHandlerId"></span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapStores } from 'pinia'
import { useAuthorizationStore } from '@/stores/authorizationStore'

export default defineComponent({
  name: 'TestHandlerInput',
  data() {
    return {
      testHandlerId: undefined
    }
  },
  watch: {
    testHandlerId(val) {
      if(val) {
        this.authorizationStore.testHandlerId = val
      }
      if(!val) {
        this.authorizationStore.testHandlerId = undefined
      }
      this.authorizationStore.setOpenAPIHeaders()
    },
  },
  computed: {
    ...mapStores(useAuthorizationStore),
  },
  methods: {
    clearTestHandlerId() {
      this.testHandlerId = undefined
    },
  },
})
</script>

<style scoped>
</style>