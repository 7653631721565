import { defineStore } from "pinia"
import { AuthService, type Organization, type User } from "@/api"

export const useProfileStore = defineStore('profile', {
  state: () => ({
    user: undefined as User | undefined,
    organizations: [] as Organization[]
  }),
  getters: {
    organizationMap(): Map<string, Organization> {
      return new Map<string, Organization>(this.organizations.map(o => [ o.id as string, o ]))
    },
    organizationId(): string | undefined {
      return (this.organizations.length ? this.organizations[0].id : undefined) ?? undefined
    },
    organization(): Organization | undefined {
      return this.organizationId
        ? this.organizations.find(o => o.id === this.organizationId)
        : undefined
    },
  },
  actions: {
    /** 
     * Initializes identity store state.
     * @returns true if the user is authorized, false otherwise
     */
    async initialize(): Promise<boolean> {
      await this.getMe()
      return !!this.user?.userId
    },
    /**
     * Retrieves profile of currently logged-in user
     */
    async getMe() {
      const me = await AuthService.getMe()
      this.user = me.user ?? undefined
      this.organizations = me.organizations ?? []
    },
  },
})