import { defineStore } from 'pinia'
import { AlertType, type Alert } from '@/shared/alerts'

export interface State {
  toast: Alert | undefined,
}

export const useAlertStore = defineStore('alert', {
  state: (): State => ({
    toast: undefined,
  }),
  actions: {
    pushToast(alert: Alert, override: boolean = true) {
      if (override || !this.toast) {
        // never override an error
        if (this.toast?.type === AlertType.Error) {
          return
        }
        this.popToast()
        this.toast = alert
      }
    },
    popToast() {
      this.toast = undefined
    },
  },
})