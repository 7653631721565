import { type Pinia, type Store, getActivePinia } from "pinia"

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>
}
export default function resetPinia() {
  const pinia = getActivePinia() as ExtendedPinia

  if (!pinia) {
    return
  }

  pinia._s.forEach((store) => {
    store.$reset();
  })
}