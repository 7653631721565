import { useIdentityStore } from "@/stores/identityStore";
import type { Router } from "vue-router";

export default (router: Router) => {
  router.addRoute({
    path: '/signIn',
    name: 'signIn',
    component: () => import('./views/SignInView.vue'),
    meta: {
      requireAnonymous: true,
    },
  })

  router.addRoute({
    path: '/signOut',
    name: 'signOut',
    beforeEnter: () => {
      useIdentityStore().signOut()
    },
    component: () => import('./views/SignInView.vue'),
  })

  router.addRoute({
    path: '/fingerprint',
    name: 'fingerprint',
    component: () => import('./views/FingerprintView.vue'),
    meta: {
      allowAnonymous: true,
    },
  })
}
