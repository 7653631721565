<template>
  <span>
    <CheckboxGroupInput 
      v-if="isAuthRoute"
      class="complete-step-overrides"
      prop-path="complete-step-overrides"
      :options="items"
      :toggleswitch="true"
      :value="authorizationStore.completeStepOverrides"
      :label-mapper="i => i.label"
      :value-mapper="i => i.value"
      :key-mapper="i => i.value"
      @emitted-data="authorizationStore.completeStepOverrides = $event.value ?? []"
    />
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapStores } from 'pinia'
import { useAuthorizationStore } from '@/stores/authorizationStore'
import { CheckboxGroupInput } from '@availity/element-vue'
import { CompleteStepOverrideOption } from '@/types/completeStepOverrides'

export default defineComponent({
  name: 'CompleteStepOverrides',
  components: {
    CheckboxGroupInput,
  },
  computed: {
    ...mapStores(useAuthorizationStore),
    items() {
      return [
        { label: 'Legacy', value: CompleteStepOverrideOption.LegacyLookAndFeel },
        { label: 'Validate Inputs', value: CompleteStepOverrideOption.ValidateInputs },
        { label: 'Validate with Payer', value: CompleteStepOverrideOption.ValidateWithPayer },
      ]
    },
    isAuthRoute() {
      return this.$route.name === 'authorization'
    },
  },
})
</script>

<style lang="scss">
fieldset.complete-step-overrides,
fieldset.complete-step-overrides:has(label:nth-of-type(3)) {
  display: flex;
  flex-direction: row;
  gap: var(--authai-layout-spacer);
}

.complete-step-overrides :deep(.toggleswitch) {
  padding: 0;
}
</style>