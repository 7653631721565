import { type PiniaPluginContext } from 'pinia'
import router from '@/router'

export function vueRouterPiniaPlugin(context: PiniaPluginContext) {
  context.store.router = router
}

declare module 'pinia' {
  export interface PiniaCustomProperties {
    router: typeof router
  }
}