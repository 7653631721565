import type { BlockItemConfig } from "@/components/blockConfigs/_Types";

export default [
  {
    type: 'Markdown',
    prop: 'markdownContent'
  },
  {
    type: 'InputToggleBox',
    label: '',
    prop: 'supplementalInformation.isAttestationLegalAgreementAccepted'
  },
] as BlockItemConfig[]