/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginViewModel } from '../models/LoginViewModel';
import type { MFACodeViewModel } from '../models/MFACodeViewModel';
import type { Organization } from '../models/Organization';
import type { OtterUserViewModel } from '../models/OtterUserViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static getCookie(
requestBody?: LoginViewModel,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Auth/GetCookie',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static logout(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Auth/Logout',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static mfa(
requestBody?: MFACodeViewModel,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Auth/Mfa',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns OtterUserViewModel OK
     * @throws ApiError
     */
    public static getMe(): CancelablePromise<OtterUserViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Auth/GetMe',
        });
    }

    /**
     * @returns Organization OK
     * @throws ApiError
     */
    public static getOrganizations(): CancelablePromise<Array<Organization>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Auth/GetOrganizations',
        });
    }

}
