import '@availity/element-css'
import '@availity/element-vue/css'
import '@/css/global.scss'

import '@/shared/extensions'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { ElementVue } from '@availity/element-vue'
import App from './App.vue'
import router, { initializeRouter } from './router'
import { vueRouterPiniaPlugin } from '@/stores/shared/router'
import appInsightsLogger from '@/plugins/appInsightsLogger'
import logger from './shared/logger'
import useIdentity from '@/identity'
import { useGlobalErrorHandler } from './composables/useGlobalErrorHandler'

import ENV from './config/environment'

if (ENV.hasSignIn) {
  useIdentity(router)
}
initializeRouter()

const app = createApp(App)

useGlobalErrorHandler(app, router)

app.use(createPinia().use(vueRouterPiniaPlugin))
  .use(router)
  .use(ElementVue)
  .use(appInsightsLogger, {
    applicationName: 'OtterUi',
    environmentName: ENV.name,
    config: {
      connectionString: ENV.APPINSIGHTS,
    },
    router,
    customLogger: logger,
  })

app.mount('#app')
app.config.globalProperties.$logger = logger

