import ENV from "@/config/environment"
import { defineStore } from "pinia"
import resetPinia from '@/stores/shared/resetPinia'
import { OpenAPI } from "@/api"
import { useOverviewStore } from "./overviewStore"
import { useProfileStore } from "./profileStore"

export const useIdentityStore = defineStore('identity', {
  state: () => ({
    isAuthenticated: false,
  }),
  actions: {
    /** 
     * Initializes identity store state.
     * @returns true if the user is authorized, false otherwise
     */
    async initialize(): Promise<boolean> {
      this.isAuthenticated = await useProfileStore().initialize() 
      await this.onAuthorized()
      return this.isAuthenticated
    },
    /**
     * Called after the user is officially authenticated
     */
    async onAuthorized(): Promise<void> {
      if (this.isAuthenticated) {
        await useOverviewStore().initialize()
      } 
    },
    /**
     * Redirects user to sign-in page configured for current environment.
     */
    redirectToSignIn() {
      if (ENV.hasSignIn) {
        this.router.push(ENV.signInUrlOrRoute)
      } else {
        window.location.replace(ENV.signInUrlOrRoute as string)
      }   
    },
    /**
     * Handles sign-out state logic and then redirects user to sign-in page.
     */
    signOut() {
      resetPinia()
      OpenAPI.HEADERS = undefined
      sessionStorage.clear()
      localStorage.clear()
      // sign-in page will make api call to set cookies to blank values
      this.redirectToSignIn()
    },
  },
})