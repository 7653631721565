import { defineStore } from 'pinia'
import { uploadQueue, authAiUploadQueue } from '@/services/uploadService'
import { UploadStatus, type UploadState, UploadResult } from '@/types/upload'
import { useAuthorizationStore } from './authorizationStore'
import { useAlertStore } from './alertStore'
import hash from 'object-hash'
import { v4 as uuidv4 } from 'uuid'
import debugLoggingService from '@/services/debugLoggingService'
import type { OtterServiceReviewViewModel, ServiceReviewAttachment } from '@/api'
import { dateService} from '@availity/element-vue'
import { defaultAlerts } from '@/shared/alerts'

function computeFingerprint(file: File): string {
  const keys = {
    name: file.name,
    type: file.type,
    size: file.size,
    lastModified: file.lastModified,
  }
  const fileHash = hash(keys, { algorithm: 'sha1' })
  return fileHash
}

export const useUploadStore = defineStore('upload', {
  state: () => ({
    uploads: new Map<string, UploadState>(),
  }),
  getters: {
    uploadAttachmentMap(): Map<string, UploadState> { // <attachmentId, attachment>
      const map = new Map<string, UploadState>()
      this.uploads.forEach(upload => {
        if (upload.attachmentId) {
          map.set(upload.attachmentId!, upload)
        }
      })
      return map
    },
    existingAttachments(): ServiceReviewAttachment[] {
      return useAuthorizationStore().serviceReview?.supplementalInformation?.attachments?.filter(attachment => {
        return attachment.id && !this.uploadAttachmentMap.has(attachment.id)
      }) ?? []
    },
    activeUploads(): Map<string, UploadState> {
      const ignoreStatuses = [UploadStatus.cancelled, UploadStatus.error]
      const map = new Map<string, UploadState>()
      this.uploads.forEach(upload => {
        if (!ignoreStatuses.includes(upload.status)) {
          map.set(upload.attachmentId!, upload)
        }
      })
      return map
    },
    totalUploadCount(): number {
      return this.uploadAttachmentMap.size + this.existingAttachments.length
    },
    totalUploadSize(): number {
      let totalSize = 0
      this.activeUploads?.forEach(u => {
        totalSize += u.bytesTotal
      })
      this.existingAttachments?.forEach(a => {
        totalSize += a.size ?? 0
      })
      return totalSize
    },
    uploadsInFlight(): boolean {
      if(this.activeUploads.size !== this.uploadAttachmentMap.size) {
        return true
      }
      return false
    }
  },
  actions: {
    upload(
      file: File | null,
      otterServiceReview: OtterServiceReviewViewModel | undefined | null) {
      if (!file || !otterServiceReview?.serviceReview?.payer?.id || !otterServiceReview.workflowId) {
        return
      }

      const uploadState = {
        key: uuidv4(),
        file,
        fingerprint: computeFingerprint(file),
        payerId: otterServiceReview.serviceReview.payer.id,
        organizationId: otterServiceReview.serviceReview.organization?.id,
        workflowId: otterServiceReview.workflowId,
        workflowStepId: otterServiceReview.workflowStepId,
        paRequestId: otterServiceReview.paRequestId,
        dashboardId: otterServiceReview.serviceReview.dashboardId,
        status: UploadStatus.created,
        bytesSent: 0,
        bytesTotal: file.size,
        message: '',
        result: UploadResult.unknown,
        error: undefined,
        bufferId: undefined,
        location: undefined,
        attachmentUri: undefined,
        attachmentId: undefined,
        password: '',
        abortCallback: undefined,
        updateStatus: this.updateStatus,
      } as UploadState

      this.uploads.set(uploadState.key, uploadState)
      this.enqueueUpload(uploadState.key)
    },
    uploadFiles(files: FileList) {
      const authorizationStore = useAuthorizationStore()
      for (var i = 0; i < files.length; i++) {
        this.upload(files.item(i), authorizationStore.otterServiceReview)
      }
      useAlertStore().pushToast(defaultAlerts.uploadStarted)
    },
    enqueueUpload(key: string) {
      const authorizationStore = useAuthorizationStore()
      if (this.validateUpload(key)) {
        const upload = this.uploads.get(key)
        this.updateStatus(upload!, UploadStatus.queued)
        if (authorizationStore.isAuthAiAttachments) {
          authAiUploadQueue.enqueue(upload!)
        } else {
          uploadQueue.enqueue(upload!)
        }
      }
    },
    sendPassword(upload: UploadState, password: string) {
      upload.password = password
      this.enqueueUpload(upload.key)
    },
    updateStatus(upload: UploadState, status: UploadStatus) {
      if (upload.status === status) {
        return
      }

      debugLoggingService.log(`Upload | Status Changing From ${UploadStatus[upload.status]} to ${UploadStatus[status]}`, { upload })
      upload.status = status

      switch (status) {
        case UploadStatus.success:
          this.onUploadSuccess(upload)
          break
        case UploadStatus.cancelled:
          this.onUploadCancelled(upload)
          break
        case UploadStatus.error:
          this.onUploadError(upload)
          break
      }
    },
    async onUploadSuccess(upload: UploadState) {
      const attachment = {
        id: upload.attachmentId,
        fileName: upload.file.name,
        mimeType: upload.file.type,
        size: upload.file.size,
        uri: upload.attachmentUri,
        dateReceived: dateService.formatDateForStorage(dateService.today()),
      } as ServiceReviewAttachment

      useAuthorizationStore().addAttachment(attachment)
      useAlertStore().pushToast(defaultAlerts.uploadSuccessful)
    },
    onUploadCancelled(upload: UploadState) {
      this.uploads.delete(upload.key)
      debugLoggingService.log('Upload Removed', { upload })
    },
    onUploadError(upload: UploadState) {
      const alert = { ...defaultAlerts.uploadError }
      if (upload.error?.message) {
        alert.message = upload.error.message
      }
      useAlertStore().pushToast(alert)
    },
    validateUpload(key: string): boolean {
      const config = useAuthorizationStore().attachmentValidationConfig
      if (!config) {
        return true
      }

      const upload = this.uploads.get(key)
      if (!upload) {
        return false
      }

      if (!upload.file.size) {
        return false
      }

      /**
       * Total number of uploads/attachments
       */
      if (config.limit && this.totalUploadCount > config.limit) {
        upload.error = new Error(config.contentType?.errorMessage ?? defaultAlerts.uploadError.message)
        this.updateStatus(upload, UploadStatus.error)
        return false
      }
      /**
       * Upload file size and total file size for all uploads/attachments
       */
      if (
        (config.max?.size && upload.bytesTotal && upload.bytesTotal > config.max.size) ||
        (config.max?.totalSize && this.totalUploadSize && this.totalUploadSize > config.max.totalSize)
      ) {
        upload.error = new Error(config.max?.errorMessage ?? defaultAlerts.uploadError.message)
        this.updateStatus(upload, UploadStatus.error)
        return false
      }

      /**
       * Upload file name
       */
      const extensionPattern = /\.[^/.]+$/
      const withoutExtension = upload.file.name.replace(extensionPattern, '')
      if (config.nameFilter?.pattern && !new RegExp(config.nameFilter.pattern).test(withoutExtension)) {
        upload.error = new Error(config.nameFilter.errorMessage ?? defaultAlerts.uploadError.message)
        this.updateStatus(upload, UploadStatus.error)
        return false
      }

      /**
       * Upload file content/mime type
       */
      const contentType = upload.file.type.toLocaleLowerCase()
      const extension = extensionPattern.exec(upload.file.name)![0]?.toLocaleLowerCase()
      if (config.contentType?.allowed && !config.contentType?.allowed.find(t => [contentType, extension].includes(t.toLocaleLowerCase()))) {
        upload.error = new Error(config.contentType.errorMessage ?? defaultAlerts.uploadError.message)
        this.updateStatus(upload, UploadStatus.error)
        return false
      }

      return true
    }
  },
})