import type { Code, PropertyValueViewModel, ServiceReviewDiagnosis, ServiceReviewPatient, ServiceReviewProcedure, ServiceReviewRenderingProvider, ServiceReviewRequestingProvider } from "@/api"
import { dateService } from "@availity/element-vue"
import type { AnyProvider } from "@/types/provider"
import type { DefaultSelectionItem } from "@availity/element-vue"

const globalFilters = {
  notSetIfEmpty(value: string | null | undefined) {
    if (value?.trim()) {
      return value
    }
    return 'not set'
  },
  formatPhoneNumber(value: string | null | undefined): string {
    if (!value) return 'not set'

    let clean = ('' + value).replace(/\D/g, '')
    let split = clean.match(/^(\d{3})(\d{3})(\d{4})$/)

    // return raw value if we can't split on (3) 3-4 format
    if (!split || !split.length) return `${value}`

    let formatted = '(' + split[1] + ') ' + split[2] + '-' + split[3]
    return formatted
  },
  formatZipPlusFour (value: string | undefined | null): string {
    if (!value) return ''
  
    if (value.length === 9) {
      return value.replace(/(\d{5})(\d{4})/, '$1-$2')
    }
  
    return value
  },
  formatWithWordsCapitalized(value: string | undefined | null): string {
    if (!value) {
      return ''
    }
    return value
      .replaceAll(/(^|\s)+[a-z]/g, s => {
        return s.toUpperCase()
      })
  },
  removeDotNotation(value: string): string {
    return value.replace(/\./g,'-')
  },
  maxCharacters(value: string, maxChars: number) {
    if (value.length > maxChars) {
      return `${value.substring(0, maxChars)}...`
    }
    return value
  },
  withSeparator(items: (string | undefined | null)[], separator: string = ' - ') {
    return items.filter(i => i).map(i => i?.trim()).join(separator)
  },
  displayName(item: DefaultSelectionItem | ServiceReviewProcedure | PropertyValueViewModel | Code): string {
    const value = item['displayName'] ?? item['value']
    return value
  },
  codeAndValue(item: DefaultSelectionItem | ServiceReviewProcedure | PropertyValueViewModel | Code): string {
    const code = item['code'] ?? item['value']
    const value = item['displayName'] ?? item['value']
    return globalFilters.withSeparator([code, value])
  },
  codeAndValueWithoutAddOn(item: DefaultSelectionItem | ServiceReviewProcedure | PropertyValueViewModel | Code): string {
    let result = this.codeAndValue(item);
    if(result.toLowerCase().endsWith('add-on')){
      result = result.slice(0, 'add-on'.length*-1);
    }
    return result;
  },
  isAddOnProcedure(item: DefaultSelectionItem | ServiceReviewProcedure | PropertyValueViewModel | Code): boolean {
    return !!this.codeAndValue(item).toLowerCase().endsWith('add-on');
  },
  name(value: ServiceReviewPatient | AnyProvider | null | undefined): string {
    if (!value) {
      return 'not set'
    }
    // most facilities only have a last name
    // Format: Last, First Middle, Suffix
    const nameAfterComma = `${value.firstName ?? ''} ${value.middleName ?? ''}${(value.suffix ? ', ' + value.suffix : '')}`.trim()
    const result = nameAfterComma
    ? `${value.lastName ?? ''}, ${nameAfterComma}`.trim()
    : value.lastName?.trim() ?? ''
    return result;
  },
  dateOfBirth(value: string | null | undefined): string {
    return globalFilters.formatDate(value)
  },
  formatDate(value: string | null | undefined): string {
    if (!value) {
      return 'not set'
    }
    const dateTime = dateService.createDateTimeFromStorageFormat(value)
    return dateService.formatDateForDisplay(dateTime)
  },
  formatDateNoDefault(value: string | null | undefined): string {
    if (!value) {
      return ''
    }
    const dateTime = dateService.createDateTimeFromStorageFormat(value)
    return dateService.formatDateForDisplay(dateTime)
  },
  formatDateTimeNoDefault(value: string | null | undefined): string {
    if (!value) {
      return ''
    }
    const dateTime = dateService.createDateTimeFromStorageFormat(value)
    return dateService.formatDateTimeForDisplay(dateTime)
  },
  formatAge(value: string): string {
    if (!value) {
      return ''
    }

    const dob = dateService.createDateTimeFromStorageFormat(value)
    if (!dob) {
      return ''
    }

    const years = Math.floor(Math.abs(dob.diffNow(['years']).years))

    return `(${years} yrs)`
  },
  address(value: ServiceReviewRequestingProvider | ServiceReviewRenderingProvider | null | undefined): string {
    if (!value) {
      return 'not set'
    }
    const lines = `${value.addressLine1 ?? ''}${value.addressLine1 && value.addressLine2 ? ', ': ''}${value.addressLine2 ?? ''}`.trim()
    const stateAndZip = `${value.state ?? ''} ${value.zipCode ?? ''}`
    return lines
      ? `${lines}, ${value.city ?? ''}, ${value.state ?? ''} ${this.formatZipPlusFour(value.zipCode)}`.trim()
      : stateAndZip.trim()
  },
  diagnosisCode(diagnosis: ServiceReviewDiagnosis): string {
    let code = diagnosis.code?.trim() ?? ''

    /**
     * If the code is a letter followed by three or more numbers then it needs to be formatted to ICD10's dotted code format.
     * The dot is always placed after the second digit (e.g. M1731 becomes M17.31).
     * 
     * The characters in an ICD10 code have the following meaning
     * @see https://www.clinicient.com/wp-content/uploads/2021/11/Clinicient-ICD-10-Chart-2048x846.png
     * 
     * [1] chapter (alpha)
     * [2-3] second level
     * .
     * [4] site
     * [5] severity
     * [6] details
     * [7] extension (alpha)
     */
    const match = code.match(/^(?<partOne>[a-zA-Z]\d{2})(?<partTwo>\d+[a-zA-Z]?)$/)
    if (match?.groups) {
      code = `${match.groups.partOne}.${match.groups.partTwo}`
    }

    return globalFilters.codeAndValue({ code, displayName: diagnosis.value })
  },
  fileSize(bytes: number) {
    return `${Math.floor(bytes / 1024 / 1024)} MB`
  },
  lowercaseString(value: string | null | undefined): string {
    if(!value){
      return ''
    }
    return value.toLocaleLowerCase()
  },
}
export default globalFilters