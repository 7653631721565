/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NextWorkflowStepViewModel } from '../models/NextWorkflowStepViewModel';
import type { OtterServiceReviewViewModel } from '../models/OtterServiceReviewViewModel';
import type { PatientAndSubscriberMapSearchResultViewModel } from '../models/PatientAndSubscriberMapSearchResultViewModel';
import type { PatientAndSubscriberViewModelSearchResultViewModel } from '../models/PatientAndSubscriberViewModelSearchResultViewModel';
import type { PatientSearchRequestViewModel } from '../models/PatientSearchRequestViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PatientsService {

    /**
     * @param requestBody 
     * @returns PatientAndSubscriberViewModelSearchResultViewModel OK
     * @throws ApiError
     */
    public static searchPatients(
requestBody: PatientSearchRequestViewModel,
): CancelablePromise<PatientAndSubscriberViewModelSearchResultViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Patients/SearchPatients',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns NextWorkflowStepViewModel OK
     * @throws ApiError
     */
    public static mapPatientAndSubscriberSearchResult(
requestBody: PatientAndSubscriberMapSearchResultViewModel,
): CancelablePromise<NextWorkflowStepViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Patients/MapPatientAndSubscriberSearchResult',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns NextWorkflowStepViewModel OK
     * @throws ApiError
     */
    public static removePatientAndSubscriber(
requestBody: OtterServiceReviewViewModel,
): CancelablePromise<NextWorkflowStepViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Patients/RemovePatientAndSubscriber',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns OtterServiceReviewViewModel OK
     * @throws ApiError
     */
    public static searchEligibility(
requestBody: OtterServiceReviewViewModel,
): CancelablePromise<OtterServiceReviewViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Patients/SearchEligibility',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
