/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MapTransportLocationViewModel } from '../models/MapTransportLocationViewModel';
import type { NextWorkflowStepViewModel } from '../models/NextWorkflowStepViewModel';
import type { RemoveServiceReviewListItemViewModel } from '../models/RemoveServiceReviewListItemViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TransportLocationsService {

    /**
     * @param requestBody 
     * @returns NextWorkflowStepViewModel OK
     * @throws ApiError
     */
    public static removeTransportLocation(
requestBody?: RemoveServiceReviewListItemViewModel,
): CancelablePromise<NextWorkflowStepViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/TransportLocations/RemoveTransportLocation',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns NextWorkflowStepViewModel OK
     * @throws ApiError
     */
    public static mapTransportLocation(
requestBody?: MapTransportLocationViewModel,
): CancelablePromise<NextWorkflowStepViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/TransportLocations/MapTransportLocation',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
