import type { BlockItemConfig } from "./_Types";

export default [
  {
    type: 'InputText',
    label: 'Member Group Number',
    prop: 'subscriber.groupNumber',
  },
  {
    type: 'InputDate',
    label: 'From Date',
    prop: 'fromDate'
  },
] as BlockItemConfig[]