import { DetailedError } from 'tus-js-client'

export enum UploadStatus {
  created = 0,
  queued = 1,
  uploading = 2,
  scanning = 3,
  encrypted = 4,
  success = 5,
  error = 6,
  cancelled = 7,
}

export enum UploadResult {
  unknown = '',
  accepted = 'ACCEPTED',
  rejected = 'REJECTED',
  encrypted = 'ENCRYPTED',
  decrypting = 'DECRYPTING',
}

export enum DecryptionResult {
  unknown = '',
  accepted = 'ACCEPTED',
  rejected = 'REJECTED',
  pending = 'PENDING',
}

export enum AvScanResult {
  unknown = '',
  accepted = 'ACCEPTED',
  rejected = 'REJECTED',
}

export interface UploadState {
  key: string,
  file: File
  fingerprint: string
  organizationId: string,
  payerId: string
  workflowId: number
  workflowStepId: number
  paRequestId: number | undefined
  dashboardId: string | undefined
  status: UploadStatus
  bytesSent: number
  bytesScanned: number
  bytesTotal: number
  message: string
  result: UploadResult
  decryptionResult: DecryptionResult
  scanResult: AvScanResult,
  error: Error | DetailedError | undefined
  bufferId: string | undefined
  location: string | undefined
  attachmentUri: string | undefined
  attachmentId: string | undefined
  password: string
  abortCallback: ((shouldTerminate?: boolean) => Promise<void>) | undefined
  updateStatus: (uploadState: UploadState, status: UploadStatus) => void
}