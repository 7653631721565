import addressConfig from '@/components/blockConfigs/Address'
import type { BlockItemConfig } from "@/components/blockConfigs/_Types";

export default [
  {
    type: 'InputText',
    label: 'Name',
    prop: "name",
  },
  ...structuredClone(addressConfig),
] as BlockItemConfig[]