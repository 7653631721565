import type { BlockItemConfig } from "@/components/blockConfigs/_Types";

export default [
  {
    type: 'InputSearch',
    label: 'Category',
    prop: 'supplementalInformation.categoryTypeCode'
  },
  {
    type: 'InputSearch',
    label: 'Service Type',
    prop: 'serviceTypeCode'
  },
  {
    type: 'InputSearch',
    label: 'Service Type',
    prop: 'serviceType'
  },
  {
    type: 'InputSearch',
    label: 'Place of Service',
    prop: 'placeOfServiceCode'
  },
  {
    type: 'InputDate',
    label: 'From Date',
    prop: 'fromDate'
  },
  {
    type: 'InputDate',
    label: 'To Date',
    prop: 'toDate'
  },
  {
    type: 'InputSelect',
    label: 'Quantity Type',
    prop: 'quantityTypeCode'
  },
  {
    type: 'InputText',
    inputType: 'number',
    label: 'Quantity',
    prop: 'quantity',
  },
  {
    type: 'InputSelect',
    label: 'Patient Condition',
    prop: 'patient.conditionCode',
  },
  {
    type: 'InputSelect',
    label: 'Patient Prognosis',
    prop: 'patient.prognosisCode',
  },
  {
    type: 'InputSelect',
    label: 'Drug Training',
    prop: 'drugTraining',
  },
  {
    type: 'InputSelect',
    label: 'Site of Service',
    prop: 'siteOfService',
  },
  {
    type: 'InputSelect',
    label: 'Transport Type',
    prop: 'transportTypeCode',
  },
  {
    type: 'InputText',
    label: 'Transport Distance (miles)',
    prop: 'transportDistance',
  },
  {
    type: 'InputTextarea',
    label: 'Transport Purpose',
    prop: 'transportPurpose',
  },
  {
    type: 'InputText',
    label: 'Site of Service Rationale',
    prop: 'siteOfServiceRationale',
  },
  {
    type: 'InputText',
    label: 'Patient Height',
    prop: 'patient.height',
  },
  {
    type: 'InputText',
    label: 'Patient Weight',
    prop: 'patient.weight',
  },
  {
    type: 'InputSelect',
    label: 'Admission Type',
    prop: 'admissionTypeCode',
  },
  {
    type: 'InputSelect',
    label: 'Admission Source',
    prop: 'admissionSourceCode',
  },
  {
    type: 'InputSelect',
    label: 'Nursing Home Residential Status',
    prop: 'nursingHomeResidentialStatusCode',
  },
  {
    type: 'InputSelect',
    label: 'Level Of Service',
    prop: 'serviceLevelCode',
  },
  {
    type: 'Markdown',
    prop: 'markdownContent'
  },
  {
    type: 'InputToggleBox',
    label: '',
    prop: 'supplementalInformation.isExpeditedReviewAgreementAccepted'
  },
] as BlockItemConfig[]