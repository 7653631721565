import type { BlockItemConfig } from "@/components/blockConfigs/_Types";

export default [
  {
    type: 'InputSelect',
    label: 'Will this member be trained to self-administer this drug?',
    prop: 'drugTraining'
  },
  {
    type: 'InputCheckboxGroup',
    label: 'Do any of the following apply?',
    prop: 'serviceLevelCode'
  },
  {
    type: 'InputTextarea',
    label: 'Provider Notes',
    prop: 'providerNotes[0].message'
  },
] as BlockItemConfig[]