/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum RequestTypeEnum {
    HS = 10,
    AR = 20,
    SC = 30,
    RX = 40,
    EXP = 99999,
}
