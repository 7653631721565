/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlobDocumentUIViewModel } from '../models/BlobDocumentUIViewModel';
import type { RemoveBlobDocumentViewModel } from '../models/RemoveBlobDocumentViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BlobDocumentsService {

    /**
     * @param formData 
     * @returns BlobDocumentUIViewModel OK
     * @throws ApiError
     */
    public static addBlobDocument(
formData?: {
WorkflowId: number;
WorkflowStepId: number;
PaRequestId?: number;
DashboardId?: string;
OrganizationId: string;
Description: string;
File: Blob;
},
): CancelablePromise<Array<BlobDocumentUIViewModel>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/BlobDocuments/AddBlobDocument',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param requestBody 
     * @returns BlobDocumentUIViewModel OK
     * @throws ApiError
     */
    public static removeBlobDocument(
requestBody?: RemoveBlobDocumentViewModel,
): CancelablePromise<Array<BlobDocumentUIViewModel>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/BlobDocuments/RemoveBlobDocument',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param workflowId 
     * @param workflowStepId 
     * @param organizationId 
     * @param paRequestId 
     * @param dashboardId 
     * @returns BlobDocumentUIViewModel OK
     * @throws ApiError
     */
    public static getBlobDocumentsForPaRequest(
workflowId: number,
workflowStepId: number,
organizationId: string,
paRequestId?: number,
dashboardId?: string,
): CancelablePromise<Array<BlobDocumentUIViewModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/BlobDocuments/GetBlobDocumentsForPaRequest',
            query: {
                'paRequestId': paRequestId,
                'workflowId': workflowId,
                'workflowStepId': workflowStepId,
                'organizationId': organizationId,
                'dashboardId': dashboardId,
            },
        });
    }

}
