import type { EpaMemberPlanResponse } from "@/api"

export enum GlobalEvents {
  /**
   * The user has completed and submitted the authorization.
   */
  Submitted = 1,
  /**
   * The user has selected to go back to payer/workflow selection and start a new authorization request.
   */
  NewRequest = 2,
  /**
   * The user has selected to go to the authorizations dashboard.
   */
  Dashboard = 3,
  /**
   * The user needs to be redirected to a different workflow based on the EPA response.
   */
  EpaRedirect = 4,
}

export type GlobalEventHandler<TPayload> = (payload?: TPayload) => void

export interface SubmittedEventPayload {
  dashboardId: string,
}

export interface EpaRedirectEventPayload {
  epaResponse: EpaMemberPlanResponse,
}

class GlobalEventService {
  handlers: Map<GlobalEvents, GlobalEventHandler<any>[]>

  constructor() {
    this.handlers = new Map<GlobalEvents, GlobalEventHandler<any>[]>()
    this.reset()
  }

  emit<TPayload>(event: GlobalEvents, payload?: TPayload) {
    const handlers = this.handlers.get(event)
    for (const handler of handlers!) {
      handler(payload)
    }
  }

  registerHandler<TPayload>(event: GlobalEvents, handler: GlobalEventHandler<TPayload>) {
    this.handlers.get(event)!.push(handler)
  }

  unregisterHandler<TPayload>(event: GlobalEvents, handler: GlobalEventHandler<TPayload>) {
    this.handlers.set(event, this.handlers.get(event)!.filter(h => h !== handler))
  }

  reset() {
    for (const event of Object.keys(GlobalEvents)) {
      this.handlers.set(GlobalEvents[event], [])
    }
  }
}

const singleton = new GlobalEventService()
export default singleton