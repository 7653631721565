import { BlockType } from '@/api'
import type { BlockConfig } from '@/components/blockConfigs/_Types'
import additionalEligibilityConfig from './AdditionalEligibility'
import contactConfig from './Contact'
// import memberSearchConfig from './MemberSearch'
import patientSubscriberConfig from './PatientSubscriber'
import providerConfig from './Provider'
import proceduresConfig from './Procedure'
import requestingProviderCongfig from './ProviderRequesting'
import serviceInformationConfig from './ServiceInformation'
import diagnosesConfig from './Diagnoses'
import isAuthRequiredConfig from './IsAuthRequired'
import messageConfig from '@/components/blockConfigs/Message'
import transportConfig from './TransportLocations'
import markdownConfig from './Markdown'
import legalConfig from './Legal'
import homeHealthConfig from './HomeHealth'

let requestingProviders = requestingProviderCongfig.map(p => {
  p.prop = `requestingProvider.${p.prop}`
  return p
})

export const BlockMap = [
  {
    title: 'Is Auth Required',
    model: null,
    component: 'IsAuthRequired',
    content: isAuthRequiredConfig,
    blockType: BlockType.IsAuthRequired,
  },
  {
    title: 'Patient Information',
    model: null,
    component: 'Patient',
    content: patientSubscriberConfig,
    blockType: BlockType.PatientSearch, // 1
  },
  {
    title: 'Patient Information',
    model: null,
    component: 'LegacyPatient',
    content: patientSubscriberConfig,
    blockType: 3,
  },
  {
    title: 'Additional Eligibility Information',
    model: null, // if model is null it will use the root of service review model
    component: 'Generic', 
    blockType: BlockType.AdditionalEligibilityInformation, // 2
    content: additionalEligibilityConfig,
  },
  {
    title: 'Requesting Provider',
    model: null,
    component: 'ProviderRequesting',
    content: requestingProviders,
    blockType: BlockType.RequestingProvider, // 150
  },
  {
    title: 'Requesting Provider',
    model: null,
    component: 'LegacyProviderRequesting',
    content: requestingProviders,
    blockType: 111,
  },
  {
    title: 'Contact Information',
    model: null,
    component: 'Generic',
    content: requestingProviders,
    blockType: BlockType.ContactInfo, // 700
  },
  {
    title: 'Rendering Providers',
    model: null,
    serviceReviewArrayProp: 'renderingProviders',
    component: 'Providers',
    content: providerConfig,
    blockType: BlockType.RenderingProvider, // 100
  },
  {
    title: 'Rendering Providers',
    model: null,
    serviceReviewArrayProp: 'renderingProviders',
    component: 'ProviderFacilitySplit',
    content: providerConfig,
    blockType: 102
  },
  {
    title: 'Rendering Providers',
    model: null,
    serviceReviewArrayProp: 'renderingProviders',
    component: 'LegacyProviders',
    content: providerConfig,
    blockType: BlockType.LegacyRendingProvider,
  },
  {
    title: 'Facility',
    model: null,
    component: 'Providers',
    content: providerConfig,
    blockType: BlockType.RenderingProvider, // 110
  },
  {
    title: 'Service Information',
    model: null, 
    component: 'ServiceInformation',
    content: serviceInformationConfig,
    blockType: BlockType.ServiceInformation, // 20
  },
  {
    title: 'Service Information',
    model: null,
    component: 'ServiceInformation',
    content: serviceInformationConfig,
    blockType: BlockType.ServiceInformationWithQuantity, // 30
  },
  {
    title: 'Transport Locations',
    model: null,
    serviceReviewArrayProp: 'transportLocations',
    component: 'TransportLocations',
    content: transportConfig,
    blockType: 70, // 70
  },
  {
    title: 'Diagnoses',
    model: null,
    serviceReviewArrayProp: 'diagnoses',
    component: 'Diagnoses',
    content: diagnosesConfig,
    blockType: BlockType.Diagnoses, // 50
  },
  {
    title: 'Procedures',
    model: null,
    serviceReviewArrayProp: 'procedures',
    component: 'Procedures',
    content: proceduresConfig,
    blockType: BlockType.Procedures, // 55
  },
  {
    title: 'Authorization Routing',
    model: null,
    serviceReviewArrayProp: 'procedures',
    component: 'Procedures',
    content: proceduresConfig,
    blockType: 60 // GorillafiedEnterProcedures 
  },
  {
    title: 'Procedures',
    model: null,
    serviceReviewArrayProp: 'procedures',
    component: 'ProceduresDetails',
    content: proceduresConfig,
    blockType: 61 // GorillafiedEditProcedures 
  },
  {
    title: 'Static',
    model: null,
    component: 'Static',
    content: markdownConfig,
    blockType: 300, 
  },
  {
    title: 'Submit to Payer',
    model: null,
    component: 'Message',
    content: legalConfig,
    blockType: BlockType.LegalText, // 960 
  },
  {
    title: 'Upload Clinical Documents',
    component: 'Attachments',
    model: 'supplementalInformation.attachments',
    serviceReviewArrayProp: 'supplementalInformation.attachments',
    blockType: BlockType.Attachments, // 500
  },
  {
    title: 'Message',
    component: 'Generic',
    content: messageConfig,
    blockType: BlockType.Message, // 510
  },
  {
    title: 'Submit',
    model: null,
    component: 'Submit',
    blockType: BlockType.Submit, // 1000
  },
  {
    title: 'Submission Result',
    model: null,
    component: 'SubmissionResult',
    blockType: BlockType.SubmissionResult, // 1010
  },
  {
    title: 'External SSO',
    model: null,
    component: 'NavChoice',
    blockType: BlockType.SSOExternal, // 9999
  },
  {
    title: 'Home Health Care',
    model: null,
    component: 'Generic',
    content: homeHealthConfig,
    blockType: BlockType.HomeHealth,
  },
  {
    title: 'Attestation',
    model: null,
    component: 'Attestation',
    blockType: 900,
  }
] as BlockConfig[]